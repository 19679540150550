// React imports

import { useEffect, useState } from 'react'
import useIsMounted from '~/hooks/useIsMounted'
import useWindowSize from '~/hooks/useWindowSize'

// Remix imports

// Third party imports

// Generated imports

// App imports

const useIsMobile = (start = true) => {
  const { isMounted } = useIsMounted()
  const [isMobile, setIsMobile] = useState(start)
  const { windowWidth } = useWindowSize()

  useEffect(() => {
    let result = true
    if (isMounted) {
      if (windowWidth > 768) {
        result = false
      }
    }
    setIsMobile(result)
  }, [isMounted, setIsMobile, windowWidth])

  return { isMobile }
}

export default useIsMobile
